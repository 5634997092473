import ApplicationLogo from "@/components/ApplicationLogo"
import { Link } from "@inertiajs/react"
import { PropsWithChildren } from "react"

export default function Guest({ children }: PropsWithChildren) {
    return (
        <div
            className={`
                flex min-h-screen flex-col items-center bg-gray-100 pt-6

                dark:bg-bonza-dark

                sm:justify-center sm:pt-0
            `}
        >
            <div className="scale-150">
                <Link href="/">
                    <ApplicationLogo
                        className={`float-left h-9 w-auto fill-current`}
                    />
                </Link>
            </div>

            <div
                className={`
                    mt-6 w-full overflow-hidden bg-white px-6 py-4 shadow-md

                    dark:bg-bonza-dark-semi

                    sm:max-w-md sm:rounded-lg
                `}
            >
                {children}
            </div>
        </div>
    )
}
